export default {
  tableItems: [
    {
      prop: 'id',
      label: 'ID',
      width: '100px',
      classname: 'text-blue'
    },
    {
      prop: 'name',
      label: 'Discharge Destination',
      classname: ''
    }
  ],
  tableActions: [
    {
      label: 'Edit',
      url: '/resources/destination/update',
      type: 'jump'
    }
  ],
  pageActions: [
    {
      label: 'Add',
      url: '/resources/destination/update',
      type: 'jump'
    }
  ]
}