<template>
  <TableViewFrame
    :tableItems="tableItems"
    :actions="actions"
    :getTableDataFunc="getDestinationList"
    table-type="destinations"
    :btnActions="pageActions"
  />
</template>
<script>
import TableViewFrame from '@/components/TableViewFrame'
import {
  getDestinationList
} from '@/services'
import jsonData from './jsonData'
export default {
  components: {
    TableViewFrame
  },
  data () {
    return {
      tableItems: jsonData.tableItems,
      actions: jsonData.tableActions,
      pageActions: jsonData.pageActions,
      getDestinationList
    }
  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
</style>